import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
function Login() {
  const [types, setTypes] = useState([]);
  const history = useHistory();
  useEffect(() => {
    axios.get('/login/getType').then((res) => {
      setTypes(res);
    });
  }, []);
  const onFinish = (e) => {
    axios.post('login/login', e).then((res) => {
      let path = '/';
      for (let i = 0; i < types.length; i++) {
        const element = types[i];
        if (element.id === e.type) {
          path += element.name;
          break;
        }
      }
      const { token, notice } = res;
      localStorage.setItem('token', token);
      message.success('登陆成功');
      history.push(path);
    });
  };

  return (
    <>
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh' }}
      >
        <Col>
          <Form onFinish={onFinish} style={{ width: 320 }}>
            <Form.Item
              name='username'
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input prefix={<UserOutlined />} placeholder='用户名' />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input
                prefix={<LockOutlined />}
                type='password'
                placeholder='密码'
              />
            </Form.Item>
            <Form.Item
              name='type'
              rules={[{ required: true, message: '请选择项目' }]}
            >
              <Select>
                {types.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                登录
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
export default Login;
