import { Card, List, Radio, Space, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
function Top() {
  const [topForm, setTopForm] = useState({
    day: "0",
    type: "0",
  });
  const [top, setTop] = useState(null);
  useEffect(() => {
    setTop(null);
    const params = { ...topForm };
    axios
      .get("common/getTop", {
        params,
      })
      .then((res) => {
        setTop(res);
      })
      .catch(() => {
        setTop([]);
      });
  }, [topForm]);

  return (
    <Card
      title="Top榜"
      extra={
        <Space>
          <Radio.Group
            defaultValue={topForm.day}
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: "当日", value: "0" },
              { label: "当月", value: "1" },
            ]}
            onChange={(e) => {
              setTopForm({ ...topForm, day: e.target.value });
            }}
          />
          <Radio.Group
            defaultValue={topForm.type}
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: "份数", value: "0" },
              { label: "金额", value: "1" },
            ]}
            onChange={(e) => {
              setTopForm({ ...topForm, type: e.target.value });
            }}
          />
        </Space>
      }
    >
      {!top ? (
        <Spin />
      ) : (
        <List
          style={{ height: "158px", overflowY: "scroll" }}
          dataSource={top}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={`${index + 1}.${item.name}`} />
              <div>
                {topForm.type === "0"
                  ? `${item.nums} 份`
                  : `${Number(item.nums).toFixed(2)} 美元`}
              </div>
            </List.Item>
          )}
        />
      )}
    </Card>
  );
}
export default React.memo(Top);
