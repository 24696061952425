import { Button, Input, message, Popover } from "antd";
import { Form } from "antd";
import axios from "axios";
import React, { memo } from "react";

const Content = memo(function index() {
  return (
    <Form
      style={{ width: 300 }}
      onFinish={({ pwd, newPwd, newPwd2 }) => {
        if (newPwd !== newPwd2) {
          message.warning("新密码两次不一致");
          return;
        }
        axios
          .post("/user/changePwd", {
            pwd,
            newPwd,
          })
          .then((res) => {
            message.success(res);
          });
      }}
    >
      <Form.Item name="pwd" label="原密码">
        <Input.Password />
      </Form.Item>
      <Form.Item name="newPwd" label="新密码">
        <Input.Password />
      </Form.Item>
      <Form.Item name="newPwd2" label="确认新密码">
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          确认修改
        </Button>
      </Form.Item>
    </Form>
  );
});

export default memo(function index() {
  return (
    <div style={{ display: "inline-block", float: "right" }}>
      <Popover placement="bottomRight" trigger="click" content={<Content />}>
        <Button type="link">修改密码</Button>
      </Popover>
    </div>
  );
});
