import { Button, Card } from 'antd';
import { Col, List, Row, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function Collection() {
  const [history, setHistory] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    axios
      .get(`collection`)
      .then((arr) => {
        setHistory(arr);
      })
      .catch((e) => {
        setHistory([]);
      });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        minHeight: '100vh',
        alignItems: 'center',
      }}
    >
      <Card>
        <Table
          dataSource={history}
          rowKey={(record) => record.name}
          columns={[
            {
              title: '问卷编号',
              dataIndex: 'name',
            },
            {
              title: '单价',
              dataIndex: 'money',
              render: (value) => value.toFixed(2),
            },
            {
              title: '今日完成',
              dataIndex: 'today',
            },
            {
              title: '累计完成',
              dataIndex: 'count',
            },
            {
              title: '操作',
              render: (value, { name }) => (
                <Button
                  onClick={async () => {
                    try {
                      setUser([]);
                      const arr = await axios.get(`collectionByName`, {
                        params: { name },
                      });
                      setUser(arr);
                    } catch {}
                  }}
                >
                  查看
                </Button>
              ),
            },
          ]}
        />
      </Card>
      <Card>
        <Table
          dataSource={user}
          rowKey={(record) => record.name}
          columns={[
            {
              title: '用户名',
              dataIndex: 'name',
            },
            {
              title: '今日完成',
              dataIndex: 'today',
            },
            {
              title: '累计完成',
              dataIndex: 'count',
            },
          ]}
        />
      </Card>
    </div>
  );
}
export default Collection;
