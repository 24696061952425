import { useInterval } from "ahooks";
import { Card, List, Tag } from "antd";
import axios from "axios";
import React, { useState } from "react";

function NewMsg() {
  const [newMsg, setNewMsg] = useState({
    id: -1,
    list: [],
  });
  const handleNewMsg = () => {
    axios.get("common/getNewMsg").then((res) => {
      setNewMsg(res);
    });
  };
  useInterval(
    () => {
      handleNewMsg();
    },
    10000,
    { immediate: true }
  );
  return (
    <Card title="最新消息">
      <List
        dataSource={newMsg.list}
        renderItem={(item, index) =>
          newMsg.id === 0 ? (
            <List.Item>
              <List.Item.Meta
                title={
                  <>
                    {`用户【${item.uname}】完成问卷 ${
                      item.tname || ""
                    } 获得金额 ${item.money} 美元 `}
                    {(item.val !== null &&
                      parseFloat(item.money) >= parseFloat(item.val) &&
                      item.type === 0 && (
                        <Tag color="error">限额${item.val} 无效</Tag>
                      )) ||
                      ""}
                    {item.des && <Tag color="blue">{item.des}</Tag>}
                  </>
                }
              />
              <div>{item.time}</div>
            </List.Item>
          ) : (
            <List.Item>
              <List.Item.Meta
                title={
                  <>
                    {`用户【${item.uname}】完成问卷 ${item.tname || ""} 获得金额
                  ${item.money} 美元`}
                    {item.des && <Tag color="blue">{item.des}</Tag>}
                  </>
                }
              />
              <div>{item.time}</div>
            </List.Item>
          )
        }
      />
    </Card>
  );
}
export default React.memo(NewMsg);
